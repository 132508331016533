.Footer01 {
  width: 100%;

  padding: 5rem 1rem 2rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  background-color: var(--clr-bg);
}

.Footer01-logo {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 1rem;
}
.Footer01-logo img {
  height: 3rem;
}

.Footer01-links {
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
}
.Footer01-links img {
  height: 2rem;
  aspect-ratio: 1/1;

  transition: 300ms;
}
.Footer01-links img:hover {
  box-shadow: 0 0px var(--clr-1);

  transform: translateY(+8px);
}

/*-- responsive --*/

@media screen and (max-width: 1000px) {
  .Footer01 {
    width: 100%;
    padding: 3rem 1rem 1rem;
  }
}
