.Navbar01 {
  position: fixed;
  top: 0;
  z-index: 10;

  width: 100%;
  padding: 1rem;

  animation: headerScroll linear forwards;
  animation-timeline: view();
  animation-range-start: 100svh;
  animation-range-end: 150svh;
}
@keyframes headerScroll {
  100% {
    background-color: var(--clr-p1);
  }
}

.Navbar01-box {
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}
.menu-icon {
  display: none;

  padding: 5px 0;

  font-weight: 900;

  border: none;
  background-color: var(--clr-3);
}

.Navbar01-logo {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 1rem;
}
.Navbar01-logo img {
  width: 3rem;
}

.Navbar01-links {
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  gap: 1rem;
}

.Navbar01Mobile {
  position: absolute;
  top: 0;
  z-index: -1;

  width: 100%;
  padding: 4rem 1rem 2rem;

  background-color: var(--clr-1);
}

.Navbar01Mobile-links {
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  gap: 1rem;
}

/*-- responsive --*/

@media screen and (max-width: 1000px) {
  .Navbar01 {
    padding: 0.5rem 1rem;
  }

  .Navbar01-links {
    display: none;
  }
  .menu-icon {
    display: block;
    width: 2rem;
  }
}
