.Token01 {
  padding: 0 1rem 8rem;

  background-image: url(./../img/bg/Asset\ 3.webp);
  background-position: left;
  background-repeat: no-repeat;
  background-size: contain;
}

.Token01-box {
  width: 100%;

  display: grid;
  grid-template-columns: 1fr 50%;
  justify-content: center;
  align-items: center;

  gap: 5rem;
}

.Token01-box > div:nth-child(2) {
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  gap: 1.5rem;
}
.Token01-box > div:nth-child(2) > div {
  width: 100%;
  padding: 1rem;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;

  background-color: var(--clr-bg);

  border-radius: 1rem;
}
.Token01-box > div:nth-child(2) > div p {
  text-align: right;
}

.Token01-box > div:nth-child(2) > div.ca {
  background-color: var(--clr-1);
  border: solid 1px var(--clr-4);
  overflow: hidden;
}
.Token01-box > div:nth-child(2) > div:last-child {
  background-color: transparent;
}

.Token01-box > img {
  position: relative;
  width: 80%;
}
.Token01-box > img::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;

  width: 500px;
  height: 500px;

  background-color: red;
  border: solid 1px var(--clr-2);
}

/*-- responsive --*/

@media screen and (max-width: 1270px) {
}

@media screen and (max-width: 1000px) {
  .Token01 {
    background-position: top;
  }

  .Token01-box {
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    gap: 2rem;
  }
}
@media screen and (max-width: 500px) {
  .Token01 {
    padding: 0 1rem 3rem;
  }
  .Token01-box > img {
    width: 90%;
  }
}
