.Stats01 {
  padding: 3rem 1rem 8rem;
  background-color: var(--clr-1);
}

.Stats01-box {
  width: 100%;

  display: grid;
  grid-template-columns: repeat(3, calc(33.33% - 2.33rem));
  gap: 2rem;
  justify-content: center;
  align-items: center;
}
.Stats01-box > div {
  width: 100%;
  padding: 2rem;

  text-align: center;

  background-color: var(--clr-bg);
  border-bottom: solid 1px var(--clr-4);
  border-radius: 1rem;
}

/*-- responsive --*/

@media screen and (max-width: 1270px) {
  .Stats01-box {
    width: 100%;

    display: grid;
    grid-template-columns: repeat(3, calc(33.33% - 1.33rem));
    gap: 2rem;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 1000px) {
  .Stats01 {
    padding: 1rem 1rem 3rem;
  }

  .Stats01-box {
    width: 100%;

    display: grid;
    grid-template-columns: 100%;
    gap: 1rem;
    justify-content: center;
    align-items: center;
  }
  .Stats01-box > div {
    width: 100%;
  }
}
