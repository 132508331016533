.Hero01 {
  padding: 12rem 1rem 8rem;

  background-image: url(./../img/bg/Asset\ 1.webp);
  background-position: left;
  background-repeat: no-repeat;
  background-size: contain;

  overflow: hidden;
}

.Hero01-box {
  width: 100%;

  display: grid;
  grid-template-columns: 50% 1fr;
  gap: 10rem;
  justify-content: space-between;
  align-items: center;
}
.Hero01-box > div:nth-child(1) {
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  gap: 1rem;
}

.Hero01-box > div:nth-child(1) > h3.ca {
  width: 100%;
  padding: 0.5rem 1rem;

  background-color: var(--clr-bg);
  border-radius: 8px;

  overflow: hidden;
  white-space: nowrap;
}
.Hero01-box > div:nth-child(1) > h3.ca span {
  color: var(--clr-4);
}

.Hero01-links {
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: start;
  gap: 1.5rem;
}
.Hero01-links img {
  height: 2rem;
  aspect-ratio: 1/1;

  transition: 300ms;
}
.Hero01-links img:hover {
  box-shadow: 0 0px var(--clr-1);

  transform: translateY(+8px);
}

.Hero01-box > img {
  width: 100%;

  animation: heroImgAnim 2s infinite;
}
@keyframes heroImgAnim {
  0% {
    transform: translateY(-5px);
  }
  50% {
    transform: translateY(+5px);
  }
  100% {
    transform: translateY(-5px);
  }
}

/*-- responsive --*/
@media screen and (max-width: 1270px) {
  .Hero01-box {
  }
}

@media screen and (max-width: 1000px) {
  .Hero01 {
    padding: 5rem 1rem 0rem;

    background-position: top;
  }

  .Hero01-box {
    position: relative;
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: center;
    align-items: center;
  }
  .Hero01-box > div:nth-child(1) {
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }

  .Hero01-box > img:nth-child(2) {
    width: 120%;
  }
}
