.Faq01 {
  padding: 0rem 1rem 8rem;

  background-image: url(./../img/bg/Asset\ 4.webp);
  background-position: right;
  background-repeat: no-repeat;
  background-size: contain;
}

.Faq01-box {
  width: 90%;
  padding: 0 5rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
}

.Faq01-box > div {
  width: 100%;
  padding: 1rem 2rem;

  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  gap: 0.5rem;

  background-color: var(--clr-bg);
  border-radius: 1rem;
}
.answer {
  display: none;
}
.answer.active {
  display: block;
}

/*-- responsive --*/

@media screen and (max-width: 1000px) {
  .Faq01 {
    margin-top: -3rem;
    padding: 5rem 1rem 8rem;

    background-position: top;
  }
  .Faq01-box {
    width: 100%;
    padding: 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
  }

  .Faq01-box > div {
    width: 100%;
    padding: 1rem;
  }
}
