.About01 {
  margin-top: -5rem;
  padding: 8rem 1rem 1rem;

  background-image: url(./../img/bg/Asset\ 2.webp);
  background-position: right;
  background-repeat: no-repeat;
  background-size: contain;
}

.About01-box {
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0;

  text-align: center;
}
.About01-box > img {
  position: relative;
  z-index: 1;

  width: 80%;
  margin-bottom: -7.2rem;
}
.About01-box > div:nth-child(1) {
  width: 100%;
  padding: 5rem 2rem 2rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  background-color: var(--clr-bg);
  border: solid 1px var(--clr-4);
  border-radius: 1rem;
}

/*-- responsive --*/

@media screen and (max-width: 1270px) {
}

@media screen and (max-width: 1000px) {
}
@media screen and (max-width: 500px) {
  .About01-box > div:nth-child(1) {
    width: 100%;
    padding: 2rem;
  }
}
